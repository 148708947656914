import React from 'react'
import SetPassword from 'domains/Affiliate/SetPassword'
import Layout from 'components/Layout'
import SEO from 'components/seo'

const SetPasswordPage = (props) => (
  <>
    <SEO title="Set Password" />
    <Layout>
      <SetPassword { ...props } />
    </Layout>
  </>
)

export default SetPasswordPage
