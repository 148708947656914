import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE } from 'utils'

export const WrapperPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 8em);

  ${({ isTextPage }) => !isTextPage &&
    css`
      background-color: #fff;
      background-image: url("/eye-illustration.png");
      background-repeat: no-repeat;
      background-size: 93%;
      background-position: center 30%;
    `}
`

export const Form = styled.form`
  padding: 3em;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border: solid 1px #bcbfc8;
  background-color: #fff;
`

export const Title = styled.div`
  font-size: 1.7em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    font-size: 1.5em;
    text-align: center;
  }
`

export const Desc = styled.div`
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${darkBlueGrey};
  margin-bottom: 2.5em;

  ${IS_FRAME_MOBILE} {
    text-align: center;
    font-size: 0.9rem !important;
  }
`

export const Gap = styled.div`
  margin-bottom: 2em;
`
